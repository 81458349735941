<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <user-view-user-info-card
            :user-data="userData"
            :fetch-user-data="fetchUserData"
          />
        </b-col>
        
        <!--
        <b-col cols="12" md="5" xl="3" lg="4">
          <user-view-user-plan-card />
        </b-col>
        -->
      </b-row>

      <b-tabs v-if="userData" pills>
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Account</span>
          </template>
          <user-view-account-detail :user-data="userData" />
          <user-view-account-media :user-data="userData" />
          <user-view-account-content :user-data="userData" />
          <user-view-account-contact :user-data="userData" />
          <user-view-account-experience :user-data="userData" />
        </b-tab>

        <!-- Tab: Products -->
        <b-tab>
          <template #title>
            <feather-icon icon="PackageIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Products</span>
          </template>
          <user-view-account-products :user-products="userProducts" />
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { BRow, BCol, BAlert, BLink, BTab, BTabs } from 'bootstrap-vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
import UserViewAccountDetail from './UserViewAccountDetail.vue'
import UserViewAccountContact from './UserViewAccountContact.vue'
import UserViewAccountContent from './UserViewAccountContent.vue'
import UserViewAccountExperience from './UserViewAccountExperience.vue'
import UserViewAccountProducts from './UserViewAccountProducts.vue'
import UserViewAccountMedia from './UserViewAccountMedia.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTab,
    BTabs,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserPermissionsCard,
    UserViewAccountDetail,
    UserViewAccountContact,
    UserViewAccountContent,
    UserViewAccountExperience,
    UserViewAccountProducts,
    UserViewAccountMedia
  },
  setup() {
    const userData = ref(null)
    const userProducts = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const fetchUserData = () =>  {
      store
        .dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
        .then(async(response) => {
          userData.value = response.data.user;
          userProducts.value = response.data.products;
        })
        .catch((error) => {
          console.log(error)
          if (error.status === 404) {
            userData.value = undefined
            userProducts.value = undefined
          }
        })
    }

    fetchUserData()

    return {
      userData,
      userProducts,
      fetchUserData
    }
  },
}
</script>

<style></style>
