<template v-if="userProducts">
  <div>
    <!-- Header: Product Info -->
    <div class="d-flex mb-1">
      <h4 class="mb-1 ml-50">Products</h4>
    </div>

    <b-card>
      <b-row v-for="(content, index) in userProducts" :key="'prod' + index">
        <b-col cols="12">
          <template>
          <!-- Basic -->
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <!-- Left: Product Image Container -->
                  <b-col
                    cols="12"
                    md="5"
                    class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
                  >
                      <div class="d-flex align-items-center justify-content-center">
                        <b-img 
                          :src="content.media.length > 0 ? content.media[0].media_path : require(`@/assets/images/pages/default-product.png`)"
                          :alt="`Image of ${content.media.length > 0 ? content.media[0].name : 'default product'}`"
                          class="w-100 product-img" />
                      </div>
                  </b-col>
                  <!-- Right: Product Detail -->
                  <b-col
                  cols="12"
                  md="7"
                  >
                    <!-- Product info -->
                    <div class="custom-label">Product ID</div>
                    <b-card-text class="mb-3">{{content.product_id}}</b-card-text>
                    <div class="custom-label">Display Status</div>
                    <b-card-text class="mb-3">{{ displayStatusDisplay[content.display_status] }}</b-card-text>
                    <!-- Product Name -->
                    <h4>{{ content.content[0].name ? content.content[0].name : '-' }}</h4>
                    <!-- Product Description -->
                    <div class="custom-label">Description</div>
                    <b-card-text class="mb-3">{{ content.content[0].description ? content.content[0].description : '-' }}</b-card-text>

                    <!-- Specification -->
                    <div class="custom-label">Specification</div>
                    <b-card-text class="mb-3" v-html="content.content[0].spec ? content.content[0].spec : '-' " />
                    
                    <!-- More about the product -->
                    <div class="custom-label">More about the product</div>
                    <b-card-text class="mb-3" v-html="content.content[0].about ? content.content[0].about : '-' " />
                    
                    <!-- Who would use this -->
                    <div class="custom-label">Who would use this</div>
                    <b-card-text class="mb-3" v-html="content.content[0].benefit_for_user ? content.content[0].benefit_for_user : '-' " />
                    
                    <!-- Who would trade this -->
                    <div class="custom-label">Who would trade this</div>
                    <b-card-text class="mb-3" v-html="content.content[0].benefit_for_partner ? content.content[0].benefit_for_partner : '-' " />

                    <!-- Industries -->
                    <div class="custom-label">Industries</div>
                    <div v-if="content.industry_list" class="mb-3 custom-badge-list">
                      <b-badge v-for="industry in content.industry_list" :key="industry.industry_id" variant="light-primary">
                        {{ industry.name }}
                      </b-badge>
                    </div>
                    <div v-else class="mb-3 custom-badge-list">-</div>

                    <!-- Saleable Market -->
                    <div class="custom-label">Saleable Market</div>
                    <div v-if="content.saleable_market_list" class="mb-3 custom-badge-list">
                      <b-badge v-for="saleableMarket in content.saleable_market_list" :key="saleableMarket.market_id" variant="light-primary" class="mr-1">
                        {{ saleableMarket.name }}
                      </b-badge>
                    </div>
                    <div v-else class="mb-3 custom-badge-list">-</div>

                    <!-- Preferred Mode -->
                    <div class="custom-label">Preferred Mode</div>
                    <div v-if="content.saleable_market_list" class="mb-3 custom-badge-list">
                      <b-badge v-for="preferMode in content.mode_preferred_list" :key="preferMode.mode_id" variant="light-primary">
                        {{ preferMode.name }}
                      </b-badge>
                    </div>
                    <div v-else class="mb-3 custom-badge-list">-</div>

                    <!-- Also Accepted -->
                    <div class="custom-label">Also Accepted</div>
                    <div v-if="content.mode_accepted_list" class="mb-3 custom-badge-list">
                      <b-badge v-for="accept in content.mode_accepted_list" :key="accept.mode_id" variant="light-primary">
                        {{ accept.name }}
                      </b-badge>
                    </div>
                    <div v-else class="mb-3 custom-badge-list">-</div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </template>
          </b-col>
      </b-row>

      <!-- Fallback -->
      <b-row>
        <b-col cols="12" md="3">
          <div v-if="!userProducts.length">-</div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BBadge,
  },
  props: {
    userProducts: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    return {
      avatarText
    };
  },
  data() {
    const displayStatusDisplay = this.$store.getters['app-user/displayStatusDisplay'];
    
    return {
      displayStatusDisplay
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
