<template v-if="userData">
  <div class="mb-2">
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <h4 class="mb-1 ml-50">Basic Information</h4>
    </div>

    <b-card>
      <!-- User Info: Input Fields -->
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Given Name -->
          <b-col cols="12" md="4">
            <b-form-group label="Given Name" label-for="givenName">
              <b-form-input
                id="givenName"
                :value="userData.given_name ? userData.given_name : '-'"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Family Name -->
          <b-col cols="12" md="4">
            <b-form-group label="Family Name" label-for="familyName">
              <b-form-input
                id="familyName"
                :value="userData.family_name ? userData.family_name : '-'"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Short Name -->
          <b-col cols="12" md="4">
            <b-form-group label="Short Name" label-for="shortName">
              <b-form-input
                id="shortName"
                :value="userData.short_name ? userData.short_name : '-'"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Given Name Local -->
          <b-col cols="12" md="4">
            <b-form-group label="Given Name (Local)" label-for="givenNameLocal">
              <b-form-input
                id="givenNameLocal"
                :value="
                  userData.given_name_local ? userData.given_name_local : '-'
                "
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Family Name Local -->
          <b-col cols="12" md="4">
            <b-form-group label="Family Name (Local)" label-for="familyNameLocal">
              <b-form-input
                id="familyNameLocal"
                :value="
                  userData.family_name_local ? userData.family_name_local : '-'
                "
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Slug -->
          <b-col cols="12" md="8">
            <b-form-group label="Slug" label-for="slug">
              <b-form-input
                id="slug"
                :value="userData.slug ? userData.slug : '-'"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Field: empty -->
          <b-col cols="12" md="4"></b-col>

          <!-- Field: Gender -->
          <b-col cols="12" md="4">
            <b-form-group label="Gender" label-for="gender">
              <b-form-input
                id="gender"
                :value="genderDisplay[userData.gender]"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Field: Birth Year -->
          <b-col cols="12" md="4">
            <b-form-group label="Birth Year" label-for="birthYr">
              <b-form-input
                id="birthYr"
                :value="userData.birth_yr"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Field: empty -->
          <b-col cols="12" md="4"></b-col>

          <!-- Field: Language -->
          <b-col cols="12" md="4">
            <b-form-group label="Language" label-for="lang_id">
              <b-form-input
                id="lang_id"
                :value="userData.availableLang.length > 0 ? userData.availableLang[0].lang.name : '-'"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: nid -->
          <b-col cols="12" md="4">
            <b-form-group label="Nationality" label-for="nid">
              <b-form-input
                id="nid"
                :value="userData.nationality ? userData.nationality.name : '-'"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Field: empty -->
          <b-col cols="12" md="4"></b-col>

          <!-- Field: role_id -->
          <b-col cols="12" md="4">
            <b-form-group label="Primary role" label-for="roleId">
              <b-form-input
                :value="
                  userData.primaryRole && userData.primaryRole.name
                    ? userData.primaryRole.name
                    : '-'
                "
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: area id -->
          <b-col cols="12" md="4">
            <b-form-group label="Current location" label-for="areaId">
              <b-form-input
                id="area_id"
                :value="userData.located ? userData.located.name : '-'"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Industries -->
          <b-col cols="12" md="4">
            <b-form-group label="Primary industry" label-for="industry_id" class="mb-2">
              <b-form-input
                id="industry_id"
                :value="
                  userData.primaryIndustry && userData.primaryIndustry.name
                    ? userData.primaryIndustry.name
                    : '-'
                "
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: linked_id -->
          <b-col cols="12" md="4">
            <b-form-group label="LinkedIn Id" label-for="linkedId">
              <b-form-input
                id="linkedId"
                :value="userData.linkedin_id ? userData.linkedin_id : '-'"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: sort -->
          <b-col cols="12" md="4">
            <b-form-group label="Sort" label-for="sort">
              <b-form-input
                id="sort"
                :value="userData.hasOwnProperty('sort') ? userData.sort : '-'"
                readonly
              />
            </b-form-group>
          </b-col>

          
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BFormInput, BForm } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    const genderDisplay = this.$store.getters['app-user/genderDisplay'];
    
    return {
      genderDisplay
    }
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()
    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    return {
      resolveUserRoleVariant,
      avatarText,
      permissionsData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
