<template v-if="userData">
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="4"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.profile_image"
            :text="avatarText(userData.display_name)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.display_name }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{
                  name: 'apps-users-edit',
                  params: { id: userData.user_id },
                }"
                variant="primary"
              >
                Edit
              </b-button>
              <a :href=" userData.slug ? 'https://manufacturese.com/profile/'+ userData.slug :'https://manufacturese.com/profile/users/'+ userData.user_id" target="_blank" class="btn ml-1 btn-outline-primary">View on web</a>
              <!-- <b-form-checkbox
                @change="toggleIndeterminate"
                class="mr-0 mt-50 ml-1"
                name="status"
                v-model="form.status"
                value="1"
                unchecked-value="0"
                switch
                >Status
              </b-form-checkbox> -->
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="4">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">User ID</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.user_id }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userStatusDisplay[userData.status] }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.primaryRole ? userData.primaryRole.name : '-' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="GlobeIcon" class="mr-75" />
              <span class="font-weight-bold">Industry</span>
            </th>
            <td class="pb-50">
              {{
                userData.primaryIndustry ? userData.primaryIndustry.name : '-'
              }}
            </td>
          </tr>
        </table>
      </b-col>

      <b-col cols="12" xl="4">
        <b-row>
          <b-col cols="6">
            <p class="font-weight-bold mb-0">Created At</p>
            <p class="mb-1">
              {{
                userData.created_at
                  ? new Date(userData.created_at).toLocaleString()
                  : '-'
              }}
            </p>
          </b-col>
          <b-col cols="6">
            <p class="font-weight-bold mb-0">Created By</p>
            <p class="mb-1">
              {{ userData.created_by ? userData.created_by : '-' }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <p class="font-weight-bold mb-0">Updated At</p>
            <p class="mb-1">
              {{
                userData.updated_at
                  ? new Date(userData.updated_at).toLocaleString()
                  : '-'
              }}
            </p>
          </b-col>
          <b-col cols="6">
            <p class="font-weight-bold mb-0">Updated By</p>
            <p class="mb-1">
              {{ userData.updated_by ? userData.updated_by : '-' }}
            </p>
          </b-col>
        </b-row>
      </b-col>
        
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'
import router from '@/router'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BForm,
    BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    fetchUserData: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  data() {
    const defaultValue = {
      status: this.userData.status
    }
    const userStatusDisplay = this.$store.getters['app-user/userStatusDisplay'];
    
    return {
      form: Object.assign({}, defaultValue),
      userStatusDisplay
    }
  },
  methods: {
    toggleIndeterminate() {
      const data = {
        user_id: this.userData.user_id,
        status: Number(this.form.status),
      }
      this.$store
        .dispatch('app-user/updateUser', {
          id: router.currentRoute.params.id,
          data: JSON.stringify(data),
        })
        .then((response) => {
          console.log(response)
          if (response.status == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'The user status is successfully updated',
                icon: 'CheckIcon',
              },
            })
            this.fetchUserData()
          }
        })
    },
  },
}
</script>

<style></style>
