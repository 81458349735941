<template v-if="userData.media">
  <div>
    <!-- Header: User Media -->
    <div class="d-flex mb-1">
      <h4 class="mb-1 ml-50">Media</h4>
    </div>
  
    <b-card>
      <b-form class="mt-1">
        <b-row align-v="center" v-for="(m, index) in userData.media" :key="'media' + index" class="mb-2">
          <b-col v-if="m.type == 'photo'" cols="12" md="2">
            <b-avatar
              :ref="'previewMedia' + index"
              :src="m.mediapath"
              :variant="`light`"
              button @click="triggerEnlargeImage(index)"
              rounded
              class="w-100 h-100"
            />
          </b-col>

          <b-modal v-if="m.type == 'photo'" :id="'imageViewer' + index" size="xl" okOnly body-class="text-center">
            <b-img :src='m.mediapath' fluid class="vh-80"/>
          </b-modal>

          <b-col v-if="m.type == 'youtube'" cols="12" md="2">
            <b-avatar
              :ref="'previewMedia' + index"
              :src="'https://img.youtube.com/vi/' + m.mediapath + '/0.jpg'"
              :href="'https://www.youtube.com/watch?v=' + m.mediapath"
              target="_blank"
              :variant="`light`"
              rounded
              class="w-100 h-100"
            />
          </b-col>

          <b-col cols="12" md="2">
            <b-form-group
              label="Media Type"
              label-for="type"
            >
              <b-form-input id="type" v-model="m.type" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Caption" label-for="caption">
                <b-form-input
                  id="caption"
                  v-model="m.caption"
                  readonly
                />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Fallback -->
        <b-row>
          <b-col cols="12" md="3">
            <div v-if="!userData.media">-</div>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { 
  BButton,
  BMedia,
  BAvatar,
  BImg,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox, } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BImg,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      avatarText,
    }
  },
  methods: {
    triggerEnlargeImage(index) {
      this.$bvModal.show('imageViewer'+index)
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
