<template v-if="userData.contact">
  <div class="mb-2">
    <!-- Header: Contact Info -->
    <div class="d-flex">
      <h4 class="mb-1 ml-50">Contact</h4>
    </div>

    <b-card>
      <!-- User Info: Input Fields -->
      <b-form class="mt-1">
        <b-row v-for="(contact, index) in userData.contact" :key="'pct' + index">
          <template v-if="contact.is_primary">
            <!-- Field: Primary Contact Type -->
            <b-col cols="12" md="2">
              <b-form-group label="Primary Contact Type" label-for="type">
                <b-form-input id="type" v-model="contact.type" readonly />
              </b-form-group>
            </b-col>

            <!-- Field: Primary Contact -->
            <b-col cols="12" md="3">
              <b-form-group label="Primary Contact" label-for="contact">
                <b-form-input
                  id="contact"
                  v-model="contact.contact"
                  :state="contact.verified ? true : false"
                  readonly
                />
              </b-form-group>
            </b-col>

            <!-- Field: Primary Contact Status -->
            <b-col cols="12" md="2">
              <b-form-group label="Primary Status" label-for="sort">
                <b-form-input
                  id="sort"
                  :value="contact.status ? contact.status : '-'"
                  readonly
                />
              </b-form-group>
            </b-col>

            <!-- Field: Primary Contact Sort -->
            <b-col cols="12" md="1">
              <b-form-group label="Primary Sort" label-for="sort">
                <b-form-input
                  id="sort"
                  :value="contact.sort ? contact.sort : '-'"
                  readonly
                />
              </b-form-group>
            </b-col>
          </template>
          <template v-else>
            <!-- Field: Secondary Contact Type -->
            <b-col cols="12" md="2">
              <b-form-group label="Secondary Contact Type" label-for="type">
                <b-form-input id="type" v-model="contact.type" readonly />
              </b-form-group>
            </b-col>
            <!-- Field: Secondary Contact -->
            <b-col cols="12" md="3" :key="'sc' + index">
              <b-form-group label="Secondary Contact" label-for="contact">
                <b-form-input
                  id="contact"
                  v-model="contact.contact"
                  :state="contact.verified ? true : false"
                  readonly
                />
              </b-form-group> </b-col
            ><!-- Field: Secondary Contact Status -->
            <b-col cols="12" md="2">
              <b-form-group label="Secondary Status" label-for="sort">
                <b-form-input
                  id="sort"
                  :value="contact.status ? contact.status : '-'"
                  readonly
                />
              </b-form-group>
            </b-col>
            <!-- Field: Secondary Contact Sort -->
            <b-col cols="12" md="1">
              <b-form-group label="Secondary Sort" label-for="sort">
                <b-form-input
                  id="sort"
                  :value="contact.sort ? contact.sort : '-'"
                  readonly
                />
              </b-form-group>
            </b-col>
          </template>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <p class=""><i>Remarks: Verified contact is checked by ✔️</i></p>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
