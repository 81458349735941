<template v-if="userData.experience">
  <div>
    <!-- Header: User Experience -->
    <div class="d-flex mb-1">
      <h4 class="mb-1 ml-50">User Experience</h4>
    </div>

    <b-card>
      <b-row
        v-for="(content, index) in userData.experience"
        :key="'exp' + index"
        v-bind:class="
          index < userData.experience.length - 1
            ? 'border-bottom border-bottom-2 mb-2'
            : 'mb-2'
        "
      >
        <template>
          <!-- Field: Title -->
          <b-col sm="12" md="2">
            <div class="custom-label">Title:</div>
          </b-col>
          <b-col cols="12" md="10">
            <div class="form-control form-group custom-content-preview">
              {{ content.extend[0].title ? content.extend[0].title : '-' }}
            </div>
          </b-col>

          <!-- Field: Company -->
          <b-col sm="12" md="2">
            <div class="custom-label">Company:</div>
          </b-col>
          <b-col cols="12" md="10">
            <div class="form-control form-group custom-content-preview">
              {{ content.extend[0].company ? content.extend[0].company : '-' }}
            </div>
          </b-col>

          <!-- Field: Time Period -->
          <b-col sm="12" md="2">
            <div class="custom-label">Time Period:</div>
          </b-col>
          <b-col cols="12" md="10">
            <div
              class="form-control form-group custom-content-preview"
              v-if="userData.current_job"
            >
              {{ content.start_date_mth }}/{{ content.start_date_yr }} -/
            </div>
            <div class="form-control form-group custom-content-preview" v-else>
              {{ content.start_date_mth }}/{{ content.start_date_yr }} -
              {{ content.end_date_mth }}/{{ content.end_date_yr }}
            </div>
          </b-col>

          <!-- Field: Location -->
          <b-col sm="12" md="2">
            <div class="custom-label">Location:</div>
          </b-col>
          <b-col cols="12" md="10">
            <div class="form-control form-group custom-content-preview">
              {{
                content.location && content.location.name
                  ? content.location.name
                  : '-'
              }}
            </div>
          </b-col>

          <!-- Field: Description -->
          <b-col sm="12" md="2">
            <div class="custom-label">Description:</div>
          </b-col>
          <b-col cols="12" md="10">
            <div
              class="form-control form-group custom-content-preview"
              v-html="
                content.extend[0].description
                  ? content.extend[0].description
                  : '-'
              "
            ></div>
          </b-col>
        </template>
      </b-row>

      <!-- Fallback -->
      <b-row>
        <b-col cols="12" md="3">
          <div v-if="!userData.experience.length">-</div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, 
    BRow,
    BCol,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
