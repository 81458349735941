<template v-if="userData.content">
  <div class="mb-2">
    <!-- Header: Contact Info -->
    <div class="d-flex mb-1">
      <h4 class="mb-1 ml-50">About User</h4>
    </div>

    <b-card>
      <b-row
        v-for="(content, index) in userData.content"
        :key="'content' + index"
      >
        <template>
          <!-- Field: Headline -->
          <b-col sm="12" md="2">
            <div class="custom-label">Headline:</div>
          </b-col>
          <b-col cols="12" md="10">
            <div
              class="form-control form-group custom-content-preview"
              v-html="content.headline ? content.headline : '-'"
            ></div>
          </b-col>

          <!-- Field: About -->
          <b-col sm="12" md="2">
            <div class="custom-label">About:</div>
          </b-col>
          <b-col cols="12" md="10">
            <div
              class="form-control form-group custom-content-preview"
              v-html="content.about ? content.about : '-'"
            ></div>
          </b-col>

          <!-- Field: Needs -->
          <b-col sm="12" md="2">
            <div class="custom-label">Needs:</div>
          </b-col>
          <b-col cols="12" md="10">
            <div
              class="form-control form-group custom-content-preview"
              v-html="content.needs ? content.needs : '-'"
            ></div>
          </b-col>

          <!-- Field: Service -->
          <b-col sm="12" md="2">
            <div class="custom-label">Service:</div>
          </b-col>
          <b-col cols="12" md="10">
            <div
              class="form-control form-group custom-content-preview"
              v-html="content.services ? content.services : '-'"
            ></div>
          </b-col>
        </template>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, 
    BRow,
    BCol,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
